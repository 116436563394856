import { Controller } from "stimulus"
import { replaceActiveButton, filterSelection } from "../helpers/filters";

export default class extends Controller {
  static targets = [ "daysCount", "patientsCount", "proceduresCount" ]

  connect() {
    this.updateProceduresCount();

    filterSelection();
    this.checkCheckedAllVisible();
  }

  onDaysCountChange = () => {
    this.updateProceduresCount();
  }

  updateProceduresCount = () => {
    this.proceduresCountTarget.value = this.proceduresCount();
  }

  proceduresCount = () => this.daysCount() * this.patientsCount();

  daysCount = () => {
    const value = this.daysCountTarget.value;
    if (!value) return 0;

    return parseInt(value);
  }

  patientsCount = () => {
    const value = this.patientsCountTarget.value;
    if (!value) return 0;

    return parseInt(value);
  }

  onOrderFormSubmit = (event) => {
    const form = event.target;
    const uncheckedItems = [];
    const formData = new FormData(form);

    for (let key of formData.keys()) {
      if (key.includes('[order]')) {
        if (!formData.getAll(key).includes('1')) {
          const item = key.replace('[order]', '');
          uncheckedItems.push(item)
        }
      }
    }

    for (let key of formData.keys()) {
      const isUnchecked = uncheckedItems.some(item => key.includes(item))
      if (isUnchecked) document.getElementsByName(key).forEach(elem => elem.disabled = true)
    }
  }

  onOrderingChange = (e) => {
    this.considerMultiplicity(e);
    this.updateDeviation(e);
  };

  updateDeviation = (e) => {
    const productContainer = e.target.closest('[data-role="product"]');
    if (!('deviation' in productContainer.dataset)) return;

    const deviationContainer = productContainer.querySelector('[data-role="deviationContainer"]');
    const orderingQuantity = parseInt(e.target.value);
    if (isNaN(orderingQuantity)) {
      deviationContainer.innerHTML = '';
      return;
    }

    const leftovers = parseInt(productContainer.dataset.leftovers);
    const norm = parseInt(productContainer.dataset.norm);
    const deviation = orderingQuantity + leftovers - norm;

    deviationContainer.innerHTML = deviation;
    deviationContainer.classList.toggle('text-danger', deviation > 0)
  };

  considerMultiplicity = (e) => {
    const productContainer = e.target.closest('[data-role="product"]');
    if (!('multiplicity' in productContainer.dataset)) return;

    const orderingQuantity = parseInt(e.target.value);
    if (isNaN(orderingQuantity)) return;

    const multiplicity = parseInt(productContainer.dataset.multiplicity);
    const multiplicity_leftover = orderingQuantity % multiplicity;
    if (multiplicity_leftover === 0) return;

    e.target.value = orderingQuantity + multiplicity - multiplicity_leftover;
  };

  filterSelectionCategory = (e) => {
    filterSelection(e.target.dataset.productType);
    replaceActiveButton(e)
    this.checkCheckedAllVisible();
  }

  checkCheckedAllVisible = () => {
    let checkedAll = true;
    const productLines = document.getElementsByClassName("product-line");

    for (let i = 0; i < productLines.length; i++) {
      const currentLine = productLines[i];
      if (currentLine.className.indexOf('show') > -1) {
        const checkbox = currentLine.getElementsByClassName("order-checkbox");
        if (!checkbox[0]?.checked) checkedAll = false;
      }
    }

    const mainCheckbox = document.getElementById('select_all');
    mainCheckbox.checked = checkedAll;
  }

  filterChecked = (e) => {
    replaceActiveButton(e)

    const checkedTypes = new Set();
    const productLines = document.getElementsByClassName("product-line");
    const productTypeLines = document.getElementsByClassName("product-type");

    for (let i = 0; i < productLines.length; i++) {
      w3RemoveClass(productLines[i], "show");
      const checkbox = productLines[i].getElementsByClassName("order-checkbox");

      if (checkbox[0]?.checked) {
        checkedTypes.add(productLines[i].dataset['productType']);
        w3AddClass(productLines[i], "show");
      }
    }

    for (let j = 0; j < productTypeLines.length; j++) {
      w3RemoveClass(productTypeLines[j], "show");
      if (checkedTypes.has(productTypeLines[j].dataset['productType'])) w3AddClass(productTypeLines[j], "show");
    }

    this.checkCheckedAllVisible()
  }

  selectAll = ({ target: { checked } }) => {
    const productLines = document.getElementsByClassName("product-line");

    for (let i = 0; i < productLines.length; i++) {
      const currentLine = productLines[i];
      if (currentLine.className.indexOf('show') > -1) {
        const checkbox = currentLine.getElementsByClassName("order-checkbox");
        if (checkbox[0]) checkbox[0].checked = checked;
      }
    }

    this.checkCheckedAllVisible()
  }
}
