export const replaceActiveButton = ({ target }) => {
  const current = document.getElementsByClassName("active");
  w3RemoveClass(current[0], "active");
  w3AddClass(target, "active");
};

export const filterSelection = (type = "") => {
  const productNodes = document.getElementsByClassName("product-line");
  const productTypeNodes = document.getElementsByClassName("product-type");

  Array.from(productNodes).forEach((productNode) => {
    w3RemoveClass(productNode, "show");
    if (productNode.className.includes(type)) w3AddClass(productNode, "show");
  });

  Array.from(productTypeNodes).forEach((productTypeNode) => {
    w3RemoveClass(productTypeNode, "show");
    if (productTypeNode.className.includes(type)) w3AddClass(productTypeNode, "show");
  });
}

