import { Controller } from "stimulus"
import { useTargetMutation } from "stimulus-use";

export default class extends Controller {
  static targets = ["messages", "count", "input", "submit", "chat"];

  connect = () => {
    this.chatTarget.scrollTop = this.chatTarget.scrollHeight;

    useTargetMutation(this);
    this.updateMessageCount();
  }

  onInputKeydown = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      this.submitTarget.click();
      this.inputTarget.value = "";
      e.preventDefault();
    }
  }

  messagesTargetAdded = (element) => {
    element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });

    this.updateMessageCount();
    this.inputTarget.value = "";
  }

  updateMessageCount = () => {
    this.countTarget.innerText = this.messagesTargets.length;
  }
}
