import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['fileInput', 'filePreview'];
  updatePreviewOnUpload = () => {
    const filePreview = this.filePreviewTarget;

    while (filePreview.firstChild) {
      filePreview.removeChild(filePreview.firstChild);
    }

    const [file] = this.fileInputTarget.files;

    if (file) {
      const { name: fileName } = file;
      const fileExtension = fileName.split('.').pop().toLowerCase();

      const previewContainer = document.createElement('div');
      w3AddClass(previewContainer, 'file-input__preview-container');

      const fileNameContainer = document.createElement('div');
      w3AddClass(fileNameContainer, 'file-input__preview-filename');
      fileNameContainer.textContent = fileName;

      const tooltipContainer = document.createElement('div');
      w3AddClass(tooltipContainer, 'tooltiptext');
      tooltipContainer.textContent = fileName;

      if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
        this.setPreviewImage({ file, previewContainer });
      } else {
        this.setPreviewIcon({ fileExtension, previewContainer });
      }

      filePreview.appendChild(previewContainer);
      filePreview.appendChild(fileNameContainer);
      filePreview.appendChild(tooltipContainer);
    }
  }

  setPreviewImage = ({ file, previewContainer }) => {
    const img = document.createElement('img');
    img.src = URL.createObjectURL(file);
    w3AddClass(img, 'file-input__preview-image');
    previewContainer.appendChild(img);
  }

  setPreviewIcon = ({ fileExtension, previewContainer }) => {
    let icon;
    switch (fileExtension) {
      case 'pdf':
        icon = 'fa-file-pdf';
        break;
      case 'doc':
      case 'docx':
        icon = 'fa-file-word';
        break;
      default:
        icon = 'fa-file';
    }

    const genericIcon = document.createElement('i');
    genericIcon.classList.add('far', 'file-input__preview-icon', icon);
    previewContainer.appendChild(genericIcon);
  };
};
