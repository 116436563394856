import { Controller } from "stimulus"
import { replaceActiveButton, filterSelection } from "../helpers/filters";

export default class extends Controller {
  connect() {
    filterSelection();
  }

  filterSelectionCategory = (event) => {
    filterSelection(event.target.dataset.productType);
    replaceActiveButton(event);
  }
}
