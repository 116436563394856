import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["start", "end"];

  updateEndMin() {
    this.endTarget.min = this.startTarget.value;
  }

  updateStartMax() {
    this.startTarget.max = this.endTarget.value;
  }
}